


























































import { Component, Watch, Mixins, PropSync } from 'vue-property-decorator'
import GetStoreMixin from '@/mixins/GetStore'
import { projectNamespace } from '@store/project'
import { ProjectActionTypes } from '@store/project/Types'
import { commonNamespace } from '@store/common'
import { Role } from '@store/common/Interface'
import { ElForm } from 'element-ui/types/form'

interface Form {
  projectId: number | null
  name: string
  inn: string
  roleId: number | null
}

@Component({
  name: 'DialogAddLegal',
})
export default class DialogAddLegalComponent extends Mixins(GetStoreMixin) {
  @PropSync('visible', Boolean) private _visible!: boolean

  @commonNamespace.State('rolesLegal')
  private rolesLegal!: Role[]

  @projectNamespace.Action(ProjectActionTypes.A_ADD_LEGAL)
  private addLegalPerson!: (person: Form) => Promise<void>

  private isExpectation: boolean = false

  private form: Form = {
    projectId: null,
    name: '',
    inn: '',
    roleId: null,
  }

  private rules: any = {
    name: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    roleId: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  private mounted() {
    this.form.projectId = this.selectedProject
  }

  private handleAddLegalPerson() {
    const form = this.$refs.form as ElForm

    form.validate(valid => {
      if (valid) {
        this.isExpectation = true
        this.addLegalPerson(this.form)
          .then(() => {
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })

            this.isExpectation = false
            this._visible = false

            form.resetFields()
          })
          .catch(error => {
            this.isExpectation = false
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      } else {
        return false
      }
    })
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    this._visible = false

    form.resetFields()
  }
}
